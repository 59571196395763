var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-2",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"height":"400px","src":_vm.logo_1,"alt":"Ferreysa"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"font-weight-bold mb-1",attrs:{"title-tag":"h2"}},[_vm._v(" Bienvenido a Ferreysa! 👋 ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" Inicie sesión en su cuenta y comience la aventura. ")]),_c('validation-observer',{ref:"loginValidation"},[_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-form-group',{attrs:{"label":"Usuario","label-for":"login-email"}},[_c('validation-provider',{attrs:{"name":"Usuario","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"login-email","state":errors.length > 0 ? false : null,"name":"login-email","placeholder":"Usuario"},model:{value:(_vm.form.userName),callback:function ($$v) {_vm.$set(_vm.form, "userName", $$v)},expression:"form.userName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"login-password"}},[_vm._v("Contraseña")])]),_c('validation-provider',{attrs:{"name":"Contraseña","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"login-password","state":errors.length > 0 ? false : null,"type":_vm.passwordFieldType,"name":"login-password","placeholder":"············"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('validation-provider',{attrs:{"name":"Conexion","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Conexión","description":"Selecciona una conexión","label-for":"idEmpresa","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"idEmpresa","options":_vm.connections,"reduce":function (option) { return option.idEmpresa; },"label":"nombre","placeholder":"Selecciona una opción","clearable":false,"searchable":false},on:{"input":_vm.selectConexion},model:{value:(_vm.form.idEmpresa),callback:function ($$v) {_vm.$set(_vm.form, "idEmpresa", $$v)},expression:"form.idEmpresa"}})],1)]}}])}),_c('validation-provider',{attrs:{"name":"Sucursal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Sucursal","description":"Selecciona una sucursal","label-for":"sucursal_Id","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"sucursal_Id","options":_vm.branches,"reduce":function (option) { return option.sucursal_Id; },"label":"nombre","placeholder":"Selecciona una opción","clearable":false,"searchable":false},model:{value:(_vm.form.sucursalId),callback:function ($$v) {_vm.$set(_vm.form, "sucursalId", $$v)},expression:"form.sucursalId"}})],1)]}}])}),_c('b-form-group'),_c('b-button',{attrs:{"disabled":_vm.loading,"type":"submit","variant":"primary","block":""},on:{"click":_vm.validationForm}},[_vm._v(" Iniciar Sesión "),(_vm.loading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e()],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }