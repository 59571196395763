<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <!-- <b-link class="brand-logo"> -->
        <!-- <vuexy-logo /> -->
        <!-- <img :src="logo" height="50">
        <h2 class="brand-text text-primary ml-1 mt-1">
          Ferreysa
        </h2> -->
      <!-- </b-link> -->
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-2">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img height="400px" :src="logo_1" alt="Ferreysa" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            Bienvenido a Ferreysa! 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Inicie sesión en su cuenta y comience la aventura.
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <!-- email -->
              <b-form-group label="Usuario" label-for="login-email">
                <validation-provider #default="{ errors }" name="Usuario" rules="required">
                  <b-form-input id="login-email" v-model="form.userName" :state="errors.length > 0 ? false : null"
                    name="login-email" placeholder="Usuario" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Contraseña</label>
                  <!-- <b-link :to="{ name: 'auth-forgot-password-v2' }">
                    <small>Forgot Password?</small>
                  </b-link> -->
                </div>
                <validation-provider #default="{ errors }" name="Contraseña" rules="required">
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input id="login-password" v-model="form.password" :state="errors.length > 0 ? false : null"
                      class="form-control-merge" :type="passwordFieldType" name="login-password"
                      placeholder="············" />
                    <b-input-group-append is-text>
                      <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Web Connections -->
              <validation-provider #default="{ errors }" name="Conexion" rules="required">
                <b-form-group label="Conexión" description="Selecciona una conexión"
                  label-for="idEmpresa" :state="errors.length > 0 ? false : null">
                  <v-select id="idEmpresa" v-model="form.idEmpresa"
                    :options="connections"
                    :reduce="option => option.idEmpresa" 
                    label="nombre" placeholder="Selecciona una opción"
                    :clearable="false" :searchable="false"
                    @input="selectConexion">
                  </v-select>
                </b-form-group>
              </validation-provider>

              <!-- Branches -->
              <validation-provider #default="{ errors }" name="Sucursal" rules="required">
                <b-form-group label="Sucursal" description="Selecciona una sucursal"
                  label-for="sucursal_Id" :state="errors.length > 0 ? false : null">
                  <v-select id="sucursal_Id" v-model="form.sucursalId"
                    :options="branches"
                    :reduce="option => option.sucursal_Id" 
                    label="nombre" placeholder="Selecciona una opción"
                    :clearable="false" :searchable="false">
                  </v-select>
                </b-form-group>
              </validation-provider>

              <!-- checkbox -->
              <b-form-group>
                <!-- <b-form-checkbox id="remember-me" v-model="status" name="checkbox-1">
                  Remember Me
                </b-form-checkbox> -->
              </b-form-group>

              <!-- submit buttons -->
              <b-button :disabled="loading" type="submit" variant="primary" block @click="validationForm">
                Iniciar Sesión
                <b-spinner v-if="loading" small/>
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import axios from 'axios'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton,
  BOverlay, BSpinner,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import vSelect from "vue-select";
import store from '@/store'
import router from '@/router'
import Swal from "sweetalert2";
import "animate.css";

import WareHousesProvider from '@/providers/WareHouses'
const WareHousesResource = new WareHousesProvider()

import BranchesProvider from "@/providers/Branches"
const BranchesResouce = new BranchesProvider()

import ConnectionsProvider from "@/providers/Connections"
const ConnectionsResource = new ConnectionsProvider()

import AuthProvider from '@/providers/Auth'
const AuthResource = new AuthProvider()


export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BOverlay, 
    BSpinner,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,

    //
    vSelect,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      loading: false,
      status: '',
      form: {
        userName: null,
        password: null,
        idEmpresa: null,
        id_Padre: null,
        almacen_Id: null,
        sucursalId: null,
      },
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      logo: require('@/assets/images/logo/ferreysa.png'),
      logo_1: require('@/assets/images/logo/ferreysa_1.jpg'),
      logo_2: require('@/assets/images/logo/ferreysa_2.jpg'),
      logo_3: require('@/assets/images/logo/ferreysa_3.jpg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      branches: [],
      wareHouses: [],
      connections: [],
      settingPurchase: {
        columns: [
          {
            key: 'show_details',
            label: '',
            description: '',
          },
          {
              key: "clave_Articulo",
              label: "Clave",
              visible: true,
              sortable: true,
              description: 'Clave del Articulo',
              thStyle: 'font-size: 0.7rem; outline: initial!important;'
          }, 
          {
            key: "nombre",
            label: "Articulo",
            visible: true,
            sortable: true,
            description: 'Nombre del Articulo',
            //tdClass: 'text-nowrap',
            thStyle: 'font-size: 0.7rem; outline: initial!important;'
          },  
          {
            key: "unidad_Venta",
            label: "Unidad V.",
            visible: true,
            sortable: true,
            description: 'Unidad de venta',
            //tdClass: 'text-nowrap',
            thStyle: 'font-size: 0.7rem; outline: initial!important;'
          },
          {
            key: "ultimo_Costo",
            label: "Costo",
            visible: true,
            sortable: true,
            description: 'Ultimo Costo',
            thStyle: 'font-size: 0.7rem; outline: initial!important;'
          },      
          {

            //VENTA MOSTRADOR PASA A ROTACION PUNTO VENTA
            key: "rotacion_Pv",
            label: "Rotación PV",
            visible: true,
            sortable: true,
            description: 'Rotación Punto Venta',
            thStyle: 'font-size: 0.7rem; outline: initial!important;'
          },
          {
              key: "rotacion_Ve",
              label: "Rotación Ventas",
              visible: true,
              sortable: true,
              description: 'Rotación Ventas',
              thStyle: 'font-size: 0.7rem; outline: initial!important;'
          },
          {
              key: "devoluciones_Totales",
              label: "Devoluciones",
              visible: true,
              sortable: true,
              description: 'Devoluciones Totales',
              thStyle: 'font-size: 0.7rem; outline: initial!important;'
          },
          {
              key: "salidas_Totales",
              label: "Salidas Por Meses",
              visible: true,
              sortable: true,
              description: 'Salidas Totales',
              thStyle: 'font-size: 0.7rem; outline: initial!important;'
          },
          {
              key: "rotacion_Pb_Ve_Proyectada",
              label: "Rotación Proyectada",
              visible: true,
              sortable: true,
              description: 'Rotación Proyectada',
              thStyle: 'font-size: 0.7rem; outline: initial!important;'
          },
          // {
          //     key: "existencia_Almacen",
          //     label: "Existencia Almacen",
          //     visible: true,
          //     sortable: true,
          //     description: 'Existencia Almacén',
          //     thStyle: 'font-size: 0.7rem; outline: initial!important;'
          // },
          {
              key: "existencia_Total",
              label: "Existencia Total",
              visible: false,
              sortable: true,
              description: 'Existencia Total',
              thStyle: 'font-size: 0.7rem; outline: initial!important;'
          },
          {
            key: "existencia_Almacen",
            label: "Existencia Almacenes",
            visible: true,
            sortable: true,
            description: 'Existencia de Almacenes',
            thStyle: 'font-size: 0.7rem; outline: initial!important;'
          },
          {
            key: "existencia_Sucursales",
            label: "Existencia Sucursales",
            visible: true,
            sortable: true,
            description: 'Existencia de Sucursales',
            thStyle: 'font-size: 0.7rem; outline: initial!important;'
          },
          {
            key: "total_meses",
            label: "Total Meses",  // = existencia almacen / (rotacion pv + totacion v)
            visible: true,
            sortable: true,
            description: 'Total Meses',
            thStyle: 'font-size: 0.7rem; outline: initial!important;'
          },
          {
              key: "fecha_Actualizacion",
              label: "Ult. Actualización",
              visible: true,
              sortable: true,
              description: 'Última Actualización',
              thStyle: 'font-size: 0.7rem; outline: initial!important;'
          },
          {
              key: "articulo_Id",
              label: "Articulo ID",
              visible: true,
              sortable: true,
              description: 'Articulo ID',
              thStyle: 'font-size: 0.7rem; outline: initial!important;'
          },
        ],
      }
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    isCashier () {
      return store.getters['auth/getUser'].customRol.id === "6c72a0d5-7a67-43f4-81ff-1ec274c9a10b" ||
        store.getters['auth/getUser'].customRol.id === "01ab07a3-50bf-483c-9fb0-b21c67b1b570"
    },
    isLogistic () {
      return store.getters['auth/getUser'].customRol.id === "18d21165-b649-4245-9cce-cc4bffa61d0b"
    },
    isOutput () {
      return store.getters['auth/getUser'].customRol.id === "5c1e9a77-e7a1-40fe-ba71-6d5c65cea4e0"
    },
    isAutorizator () {
      return store.getters['auth/getUser'].customRol.id === "9da2306d-48fc-4359-af70-27fb9eeb5440"
    },
    isAdministrator() {
      return store.getters['auth/getUser'].customRol.id == store.getters['auth/getAdministratorRol']
    },
    isSuperCash() {
      return store.getters['auth/getUser'].customRol.id == store.getters['auth/getSuperCashierRol']
    },
  },
  created() {    
    if (process.env.NODE_ENV === 'development') {
      this.form.userName = 'admin'
      this.form.password = 'Adm1n2024_'
    } else {
      this.form.userName = null
      this.form.password = null
    }
  },
  async mounted() {
    await this.getConnections()
  },
  methods: {
    getWareHouses() {
      try {
        
      }catch(e) {
        this.loading = false
      }
    },
    getLocalHost() {
      const hostname = window.location.hostname;
      if (hostname === 'localost' || hostname === '127.0.0.1') {
        return false
      }
      return hostname === '192.168.1.1';
    },
    async selectConexion(evt) {
      if (evt) {
        const idPadre = this.connections.find(item => item.idEmpresa == evt)
        // if (evt === 1 && !this.getLocalHost()) {
        //   this.$swal.fire({
        //     title: 'Conexión Local',
        //     text: 'Esta conexión solo se puede establecer de forma local. Será redirigido a una nueva ruta. ¿Desea proceder?',
        //     icon: 'question',
        //     showCancelButton: true,
        //     confirmButtonText: 'Continuar',
        //     cancelButtonText: 'Cancelar',
        //     customClass: {
        //         confirmButton: "btn btn-primary",
        //         cancelButton: "btn btn-outline-danger ml-1",
        //     },
        //     showClass: {
        //         popup: "animate__animated animate__flipInX",
        //     },
        //     buttonsStyling: false,
        //   }).then((result) => {
        //     if(result.isConfirmed) {
        //       window.location.href = "http://192.168.1.1:8081"
        //     } else {
        //       this.form.idEmpresa = null
        //       this.form.id_Padre = null,
        //       this.form.almacen_Id = null
        //       this.form.sucursalId = null
        //     }
        //   })
        // } else {
          if (this.form.idEmpresa) {
            this.form.id_Padre = idPadre.id_Padre
            await this.getBranches()
          }
        // }
      }
    },
    async getBranches () {
      try {
        this.branches = []
        this.form.sucursalId = null
        this.loading = true
        const { data } = await BranchesResouce.index(this.form.idEmpresa)
        this.loading = false
        if (data.isSuccesful) {
          this.branches = data.data
        } else {
          this.danger(data.message)
        }
        
      }catch(e) {
        this.loading = false
        this.handleResponseErrors(e)
      }
    },
    async getConnections() {  
      try {    
        this.loading = true
        const { data } = await ConnectionsResource.getByStatus(1) //1 = Activa
        this.loading = false
        if (data.isSuccesful) {
          this.connections = data.data
          if (this.connections) {
            store.dispatch('auth/UPDATE_CONNECTIONS', this.connections)
          }
        } else {
          this.danger(data.message)
        }
      }catch(e) {
        console.log(JSON.stringify(e))
        this.loading = false
        this.handleResponseErrors(e)
      }
    },
    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.loading = true
          
          store.dispatch('routing/UPDATE_IS_RELOAD_ABIERTA', false)
          store.dispatch('routing/UPDATE_IS_RELOAD_EN_RUTA', false)
          store.dispatch('routing/UPDATE_IS_RELOAD_CERRADA', false)
          store.dispatch('routing/UPDATE_IS_RELOAD_CLIENTE_PASA', false)

          store.dispatch("auth/GET_AUTH_TOKEN", this.form).then((response) => {
            this.loading = false
            this.success(`Bienvenido ${this.form.userName}`);
            store.dispatch('auth/UPDATE_BRANCH', this.branches.find(item => item.sucursal_Id === this.form.sucursalId))
            store.dispatch('auth/UPDATE_CONEXION', this.connections.find(item => item.idEmpresa === this.form.idEmpresa))
            //store.dispatch('auth/UPDATE_WAREHOUSE', this.wareHouses.find(item => item.almacen_Id === this.form.almacen_Id))
            store.dispatch('auth/UPDATE_SETTING_PURCHASE', this.settingPurchase)


            const wareHouses = store.getters['auth/getWareHouses']
            let nuevoAlmacen = 0;
            if (store.getters['auth/getUser'].almacenes.length > 0) {
                const wareHouse = wareHouses.find(wh => wh.almacen_Id == store.getters['auth/getUser'].almacenes[0].almacen_Id)
                nuevoAlmacen = wareHouse ? wareHouse.almacen_Id : 0
            }

            if (this.isSuperCash) {
              nuevoAlmacen = 0
            }
            
            store.dispatch('auth/UPDATE_WAREHOUSE_ID', nuevoAlmacen) 

            if (this.isCashier /*|| this.isLogistic*/) {
              router.push({ name: 'apps-routings' })
            } else if (this.isOutput || this.isAutorizator) {
              router.push({ name: 'apps-routings-outputs' })
            } else {
              router.push({ name: 'home' })
            }
          }).catch(error => {
            console.log(error)
            this.loading = false
            const { data } = error
            if (data) {
              if (!data.IsSuccesful) {
                this.danger(data.message)
              }
            } else {
              this.handleResponseErrors(error);
            }
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import "~@core/scss/vue/libs/vue-select.scss";
</style>